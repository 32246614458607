import moment from "moment/moment"
import { getAvr, segment } from "../SoloResortChart/prepareData"
import { resortNames } from "@utils/const"

export function prepareDataMultiple(statistics, mobile, points_on_contract = 100, resortsById = {}, chartOptions) {
    const limit = mobile ? -6 : -20
    const datasets = []
    Object.values(statistics).forEach(({ data: statistic, resort, enabled }) => {
        const now = moment()
        const priceStatistics = new Map((statistic?.priceStatistics || []).map((i) => [moment(i.date).startOf("day").valueOf(), i]))
        let labels = [...priceStatistics.keys()]

        labels.sort()

        priceStatistics.forEach((i, k) => {
            let res
            if (i) {
                res = getAvr(i, points_on_contract)
            }
            priceStatistics.set(k, res || null)
        })

        const groupLabels = new Map()
        labels.forEach((i) => {
            const key = moment(i).endOf("month").valueOf()
            const date = moment(i)
            if (!groupLabels.has(key)) {
                const avr = priceStatistics.get(i) ? [priceStatistics.get(i)] : []
                groupLabels.set(key, {
                    avr,
                    order: key,
                    rawDate: i,
                    text: now.isSame(moment(i), "month") ? date.fromNow() : moment(i).format("MMMM YY")
                })
            } else {
                const avr = priceStatistics.get(i) ? [priceStatistics.get(i)] : []
                const item = groupLabels.get(key)
                item.avr = item.avr.concat(avr)
                if (item.rawDate < i) {
                    item.text = now.isSame(moment(i), "month") ? date.fromNow() : moment(i).format("MMMM YY")
                }
            }
        })
        groupLabels.forEach((i) => {
            i.value = i.avr.length > 1 ? i.avr.reduce((a, b) => (a + b) / 2) : i.avr[0] || null
        })

        const color = resortsById[resort]?.color
        const shorName = resortNames[resortsById[resort]?.short_name] || ""
        datasets.push({
            spanGaps: true,
            segment,
            resort: resort,
            enabled,
            label: (shorName ? shorName + " - " : "") + "Avg resale price",
            groupLabels,
            data: [],
            borderColor: color,
            backgroundColor: color,
            pointStyle: "circle",
            tension: chartOptions?.tension || 0,
            pointRadius: 5,
            borderWidth: 2,
            pointBorderWidth: 1.75,
            pointBorderColor: "#30254C",
            pointHoverRadius: 10
        })
    })
    let labels = {}
    // save all labels
    datasets.forEach((i) => {
        i.groupLabels.forEach((j) => {
            labels[j.order] = j.text
        })
    })

    const labelKeys = Object.keys(labels).sort(orderLabels)
    // fill the data
    datasets.forEach((i) => {
        labelKeys.forEach((j) => {
            if (!i.groupLabels.has(+j)) {
                i.groupLabels.set(+j, {
                    order: j,
                    value: null
                })
            }
        })
        const d = Array.from(i.groupLabels)
            .map((i) => i[1])
            .sort((a, b) => orderLabels(a.order, b.order))
            .map((i) => i.value)

        i.data = replaceNullWithAdjacentValues(d).slice(limit)
    })
    const l = Object.keys(labels)
        .sort(orderLabels)
        .map((i) => labels[i])
        .slice(limit)
    return { datasets, labels: l }
}

function orderLabels(a, b) {
    return Number(a) - Number(b)
}
function replaceNullWithAdjacentValues(arr) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === null) {
            let j = i
            while (arr[j] === null && j < arr.length - 1) {
                j++
            }
            if (i === 0) {
                arr[i] = arr[j]
            } else if (j === arr.length - 1) {
                arr[i] = arr[i - 1]
            } else {
                arr[i] = arr[i - 1] !== null ? arr[i - 1] : arr[j]
            }
        }
    }
    return arr
}

export const colors = {
    "--color1": "#9c27b0",
    "--color2": "#ff6037",
    "--color3": "#aaf0d1",
    "--color4": "#ff9933",
    "--color5": "#ffcc33",
    "--color6": "#ff355e",
    "--color7": "#ff00cc",
    "--color8": "#fd5b78",
    "--color9": "#50bfe6",
    "--color10": "#ff9966",
    "--color11": "#16d0cb",
    "--color12": "#ffff66",
    "--color13": "#ee34d2",
    "--color14": "#66ff66",
    "--color15": "#ccff00",
    "--color19": "#ff0000"
}
